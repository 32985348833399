<template>
  <nav
    :class="{ 'nav-container': true, pageHome: isPageHome }"
    :style="{ 'background-color': bgColor ? bgColor : '' }"
    v-if="!isMobile"
  >
    <div class="nav m-auto">
      <div class="nav-l">
        <!-- <div class="logo"></div> -->
        <svg-icon icon-class="banner" class-name="logo"></svg-icon>
      </div>
      <div class="nav-center">
        <!-- @click="routeTo(item.key, idx)" -->
        <!-- :href="item.key" -->
        <a
          class="nav-one"
          :href="item.key + '/'"
          @mouseover="navMouseOver(idx)"
          @mouseout="navMouseOut(idx)"
          :class="{ active: idx === activeIdx }"
          v-for="(item, idx) in navList"
          :key="item.key"
        >
          {{ item.title }}
          <div
            class="nav-one-modal"
            v-show="item.children && hoverIdx === idx"
            :style="{ width: item.key === '/product' ? '130px' : '130px' }"
            @click.stop=""
          >
            <div class="nav-one-modal-list">
              <!-- <div
                class="nav-one-modal-list-item"
                :style="{
                  color:
                    activeIdx === idx && modalIndex === activeChildIdx
                      ? '#2871ff'
                      : '',
                }"
                v-for="(modalItem, modalIndex) in item.children"
                :key="modalItem.key"
                @click.stop="childRouteTo(modalItem.key, modalIndex)"
              >
                <img v-if="modalItem.img" :src="modalItem.img" alt="" />
                <div class="nav-one-modal-list-item-title">
                  {{ modalItem.title }}
                </div>
              </div> -->
              <a
                :href="modalItem.key + '/'"
                class="nav-one-modal-list-item"
                :style="{
                  color:
                    activeIdx === idx && modalIndex === activeChildIdx
                      ? '#2871ff'
                      : '',
                }"
                v-for="(modalItem, modalIndex) in item.children"
                :key="modalItem.key + modalIndex"
              >
                <img v-if="modalItem.img" :src="modalItem.img" alt="" />
                <div class="nav-one-modal-list-item-title">
                  {{ modalItem.title }}
                </div></a
              >
            </div>
          </div>
        </a>
      </div>
      <!-- <div class="nav-r">
        <span @click="visible = true">体验产品</span>
      </div> -->
    </div>
    <modal v-show="visible" @close="visible = false"> </modal>
  </nav>
  <nav class="m-nav-container" v-else>
    <div class="m-nav">
      <div>
        <svg-icon
          icon-class="toggle"
          class-name="toggle"
          @click="isToggle = !0"
        ></svg-icon>
        <svg-icon icon-class="banner" class-name="logo"></svg-icon>
      </div>
      <span class="experience-the-product" @click="visible = true"
        >产品体验</span
      >
    </div>
    <transition name="slide">
      <div class="drawer" v-show="isToggle">
        <div class="nav-top">
          <svg-icon
            icon-class="toggle-close"
            class-name="toggle-close"
            @click="isToggle = !1"
          ></svg-icon>
          <svg-icon icon-class="top-logo" class-name="top-logo"></svg-icon>
        </div>
        <div class="nav-main">
          <div
            class="nav-one"
            v-for="(item, idx) in navList"
            :key="item.key"
            @click="routeTo(item.key, idx)"
          >
            <svg-icon :icon-class="item.icon" class-name="nav-icon"></svg-icon>
            <div class="title">{{ item.title }}</div>
            <svg-icon
              icon-class="arrow-left"
              class-name="arrow-left"
            ></svg-icon>
          </div>
        </div>
      </div>
    </transition>
    <div class="drawer-mask" v-show="isToggle" @click="isToggle = !1"></div>
    <modal v-show="visible" @close="visible = false">
      <svg-icon style="transform: scale(1.5)" url="case-qrcode"></svg-icon>
    </modal>
  </nav>
</template>

<script>
import Modal from '@/components/modal'
import checkIsMobile from '@/mixin/checkIsMobile'
import { navList } from '@/config'
import router from '@/router'
export default {
  name: 'Navgation',
  mixins: [checkIsMobile],
  props: ['isPageHome', 'bgColor'],
  data() {
    return {
      visible: false,
      isToggle: false,
      activeIdx: 0,
      hoverIdx: undefined,
      navList,
      activeChildIdx: 0,
    }
  },
  components: { Modal },
  mounted() {
    const key = this.$route.path
    this.activeIdx = navList.findIndex((item) => key.includes(item.key)) || 0
    console.log(key)
    this.activeChildIdx = navList.find((item) => key.includes(item.key))
      ?.children
      ? navList
          .find((item) => key.includes(item.key))
          .children.findIndex((item) => key === item.key)
      : 0
    // router.beforeEach((to, from, next) => {
    //   if (to.name.indexOf('home') !== -1) {
    //     document
    //       .querySelector('meta[name="keywords"]')
    //       .setAttribute(
    //         'content',
    //         '注册公司,公司注册,公司注册代理,公司注册代办,公司起名,起名公司,十角兽'
    //       )
    //     document
    //       .querySelector('meta[name="description"]')
    //       .setAttribute(
    //         'content',
    //         '海南十角兽网络科技有限公司是专业的注册公司代理及公司起名的企业,为广大创业者、中小型企业提供专业的注册代理服务.多年企业服务经验,专业化团队办理,值得您的信赖.'
    //       )
    //     document.title = '注册公司_公司起名_公司注册代理-十角兽'
    //   } else if (to.name.indexOf('product') !== -1) {
    //     document
    //       .querySelector('meta[name="keywords"]')
    //       .setAttribute(
    //         'content',
    //         '新公司注册,代理注册公司,注册公司代办, 公司注册流程'
    //       )
    //     document
    //       .querySelector('meta[name="description"]')
    //       .setAttribute(
    //         'content',
    //         '十角兽专注于为企业提供新公司注册,代理注册公司,注册公司代办等企业服务,积累多年注册代理服务经验,诚信可靠,快速高效.'
    //       )
    //     document.title = '新公司注册_代理注册公司_注册公司代办'
    //   } else if (to.name.indexOf('partner') !== -1) {
    //     document
    //       .querySelector('meta[name="keywords"]')
    //       .setAttribute(
    //         'content',
    //         '注册公司代理,注册公司流程,代理公司注册,代办注册公司'
    //       )
    //     document
    //       .querySelector('meta[name="description"]')
    //       .setAttribute(
    //         'content',
    //         '十角兽专业提供注册公司代理,代理公司注册,注册公司流程及费用,工商营业执照代理,财务代理等众多业务,找十角兽代办让创业变得更简单.'
    //       )
    //     document.title = '注册公司代理_注册公司流程_代理公司注册'
    //   } else if (to.name.indexOf('news') !== -1) {
    //     document
    //       .querySelector('meta[name="keywords"]')
    //       .setAttribute('content', '公司取名,公司起名字,公司取名大')
    //     document
    //       .querySelector('meta[name="description"]')
    //       .setAttribute(
    //         'content',
    //         '海南十角兽,是专业给公司取名大全的网站,擅长公司企业起名、有各行业取名经验,提供免费起名字参考,打造起名行业诚信口碑.'
    //       )
    //     document.title = '公司取名_公司起名字_公司取名大全'
    //   } else if (to.name.indexOf('case') !== -1) {
    //     document
    //       .querySelector('meta[name="keywords"]')
    //       .setAttribute('content', '公司测名,公司测名字,公司取名字')
    //     document
    //       .querySelector('meta[name="description"]')
    //       .setAttribute(
    //         'content',
    //         '十角兽公司取名网,专业取名网站,免费为广大创业老板提供公司起名,公司取名,公司测名,企业名称注册查询系统精准对接工商大数据,解决公司起名核名、商标查询、经营范围生成等新注册企业遇到的工商问题.'
    //       )
    //     document.title = '公司测名_公司测名字_公司取名字'
    //   }
    //   next()
    // })
  },
  methods: {
    routeTo(path, idx) {
      if (this.isMobile) {
        this.isToggle = false
      }
      this.activeIdx = idx
      const dom = document.querySelector('.__panel')
      dom.scrollTop = 0
      this.$router.push({ path })
    },
    childRouteTo(path, modalIndex) {
      this.activeChildIdx = modalIndex
      const dom = document.querySelector('.__panel')
      dom.scrollTop = 0
      this.$router.push({ path })
    },
    navMouseOver(idx) {
      this.hoverIdx = idx
    },
    navMouseOut() {
      this.hoverIdx = undefined
    },
  },
}
</script>
<style scoped lang="less">
.nav-container {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 10;
  height: @navHeight;
  background-color: #182030;
  &.pageHome {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 10;
    background-color: transparent;
  }
  .nav {
    width: @panelWidth;
    display: flex;
    align-items: center;
    height: 100%;
    &-l {
      width: 400px;
      .logo {
        display: block;
        // margin: 0 auto;
        margin-left: 156px;
        width: 107px;
        height: 35px;
        // background-size: 100% 100%;
        // background-repeat: no-repeat;
        cursor: pointer;
      }
    }
    &-center {
      flex: 1;
      display: flex;
      .nav-one {
        cursor: pointer;
        font-size: 18px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(255, 255, 255, 0.5);
        margin-left: 82px;
        border-bottom: 1px solid transparent;
        // opacity: 0.5;
        padding: 16px 0;
        position: relative;
        text-decoration: none;
        &:first-child {
          margin-left: 0;
        }
        &:hover {
          // opacity: 1;
          color: rgba(255, 255, 255);
        }
        &-modal {
          position: absolute;
          top: 45px;
          z-index: 11;
          padding-top: 10px;
          &-list {
            background: #ffffff;
            padding: 6px;
            border-radius: 9px;
            &-item {
              padding: 10px 2px;
              display: flex;
              align-items: center;
              color: #102a55;
              text-decoration: none;
              img {
                width: 24px;
                height: 24px;
                margin-right: 8px;
              }
              &-title {
                font-size: 15px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
              }
              &:hover {
                color: #2871ff;
              }
            }
          }
        }
      }
      .active {
        // opacity: 1;
        color: rgba(255, 255, 255);
        border-bottom: 1px solid #ffffff;
      }
    }
    &-r {
      width: 86px;
      span {
        white-space: nowrap;
        cursor: pointer;
        padding: 6px 15px;
        font-size: 14px;
        font-family: AlibabaPuHuiTiR;
        color: #ffffff;
        background: rgba(255, 255, 255, 0.08);
        border-radius: 100px;
        border: 1px solid #ffffff;
        &:hover {
          color: #182030;
          background-color: #ffffff;
        }
      }
    }
  }
}
.m-nav-container {
  position: relative;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 10;
  .m-nav {
    height: @mNavHeight;
    background-color: #182030;
    display: flex;
    padding: 0 35px;
    align-items: center;
    justify-content: space-between;
    .toggle {
      font-size: 64px;
    }
    .logo {
      width: 149px;
      height: 48px;
      padding-left: 24px;
    }
    .experience-the-product {
      background: rgba(255, 255, 255, 0.12);
      border-radius: 100px;
      border: 1px solid #ffffff;
      font-size: 26px;
      font-family: AlibabaPuHuiTiR;
      color: #ffffff;
      padding: 12px 45px;
      line-height: 35px;
    }
  }
  .drawer {
    position: fixed;
    top: 0;
    left: 0;
    width: 70%;
    height: 100%;
    z-index: 9999999;
    background-color: #fff;
    .nav-top {
      display: flex;
      align-items: center;
      height: @mNavHeight;
      background-color: #fff;
      box-shadow: -13px 7px 16px #e4e4e4; /* no */
      padding: 0 40px;
      .toggle-close {
        font-size: 64px;
      }
      .top-logo {
        font-size: 150px;
        margin-left: 40px;
      }
    }
    .nav-main {
      height: calc(100vh - @mNavHeight);
      background-color: #f6f8fa;
      overflow-y: auto;
      .nav-one {
        display: flex;
        padding: 28px 0;
        margin: 0 40px;
        align-items: center;
        border-bottom: 1px solid #e3e8ee;
        .title {
          padding-left: 35px;
          flex: 1;
          font-size: 32px;
          font-family: AlibabaPuHuiTiR;
          color: #000000;
        }
        .nav-icon {
          font-size: 45px;
        }
        .arrow-left {
          font-size: 45px;
        }
      }
    }
  }
  .drawer-mask {
    z-index: 999999;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.4);
  }
  .slide-enter-active,
  .slide-leave-active {
    will-change: transform;
    transition: transform 0.2s ease-in-out;
  }
  .slide-leave-to,
  .slide-enter {
    transform: translateX(-100%);
  }
}
</style>
