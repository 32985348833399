import Vue from 'vue'
import VueRouter from 'vue-router'
import Router from 'vue-router'

let routes = []

try {
  const context = require.context('./modules', true, /\/.*\.js$/)
  context.keys().forEach((key) => {
    const appConf = context(key).default
    if (appConf instanceof Array) {
      routes = [...routes, ...appConf]
    } else {
      routes.push(appConf)
    }
  })
} catch (e) {
  console.error(e)
}

const originalPush = Router.prototype.push
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch((err) => err)
}

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})
export default router
