import Vue from 'vue'
import axios from 'axios'
import App from './App.vue'
import router from './router'
import store from './store'
import FastClick from 'fastclick'
import Element from "element-ui";
import 'element-ui/lib/theme-chalk/index.css'

import './assets/style/reset.css'
import './assets/style/common.css'
import './assets/style/font.less'

import './utils/rem'
import './utils/storage'
import './utils/dependencies'

// 引入
import MetaInfo from 'vue-meta-info'
// 使用
Vue.use(MetaInfo)

Vue.prototype.$axios = axios
FastClick.attach(document.body)

Vue.use(Element);

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: (h) => h(App),
  // 需要加上下面这个东西
  mounted () {
    document.dispatchEvent(new Event('render-event')) // 需要加上
  }
}).$mount('#app')

